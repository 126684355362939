import React from 'react'

const CollectedLettersComponent = ({ collectedLetters, onClose }) => {
    return (
        <div className='fixed top-0 left-0 w-full h-full z-[1100] flex items-center justify-center bg-gray-600/60'>
            <div className='bg-white p-6 rounded-xl shadow-lg max-w-md w-full'>
                <h2 className='text-2xl font-semibold text-gray-800 mb-4'>Collected Letters</h2>
                <p className='text-lg font-normal text-gray-800 mb-4'>{collectedLetters.join(' ')}</p>
                <button onClick={onClose} className='py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600'>Close</button>
            </div>
        </div>
    )
}

export default CollectedLettersComponent