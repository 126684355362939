import React from 'react';

const Welcome = ({ onStartGame, location }) => {
    return (
        <div className='fixed inset-0 z-[1100] flex items-center justify-center bg-gray-600/60'>
            <div className='bg-white p-6 rounded-xl shadow-inner max-w-md w-full max-h-[90vh] overflow-auto'>
                <h2 className='text-2xl font-semibold text-gray-800 mb-4'>Velkommen til Escape City Game {location}!</h2>
                <p className='text-lg font-normal text-gray-800 mb-4'>På dette spillet vil dere måtte følge kartet på denne nettsiden. Det vil dukke opp røde spørsmålstegn på kartet som dere må gå til, for å få opp et spørsmål. Dere vil da få svaralternativer. Svaret kan alltid finnes i virkligheten. Dette kan være tekst på bygg, farger, eller lignende. Om dere svarer riktig, vil dere få en bokstav. Jo flere spørsmål dere svarer riktig på, jo nærmere kommer dere det hemmelige ordet. Dere trenger dette ordet for å rømme. Om dere svarer feil på ett spørsmål kan dere fortsatt fortsette med spillet, men dere får ikke en bokstav for det spørsmålet.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Her kommer historie....</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Dere har 2 timer på å hjelpe (navn). Når dere er ferdig, vil tiden deres bli registrert og visst på sluttsiden, samt andre statistikk fra løpet deres.</p>
                <p className='text-2lg font-bold text-gray-800 mb-4'>Lykke til!</p>
                <button className='py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600' onClick={onStartGame}>Start spillet!</button>
            </div>
        </div>
    )
}

export default Welcome